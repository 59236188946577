<template>
  <!-- 2021年5月25号开发完成  开发人员：王世龙 开发模块运营赋能 -->
  <div>
    <main>
      <div class="Banner">
        <img v-lazy="require('./img/banner.webp')" alt="" class="banner" />
        <span class="yyfn">运营赋能</span>
        <span class="js"
          >以智能化运用赋能精细化管理，进一步提升了精细化管理水平</span
        >
      </div>
      <div class="bg1">
        <div class="yy">
          <img v-lazy="require('./img/bg1.webp')" alt="" class="a" />
          <span class="yyl">运营类</span>
          <span class="yyjs"
            >发展现代特色化项目，增加推广人群，提高知名度。增加竞争力</span
          >
        </div>
        <div class="box1">
          <div class="box2">
            <span class="ghyy">文旅项目规划运营</span>
            <span class="cysj">赋能文旅项目，整合资源，推动产业升级</span>
            <el-button type="text" class="ljxq" @click="jump('/trapoer')"
              >了解详情 ></el-button
            >
          </div>
          <div class="box3">
            <span class="yyfn">文旅项目运营赋能</span>
            <span class="fnnl"
              >旅游资源整合、旅游目的地营销、平台及区域运营赋能能力</span
            >
            <el-button type="text" class="ljxq1" @click="jump('/operation')"
              >了解详情 ></el-button
            >
          </div>
          <div class="box4">
            <span class="yyfn1">研学运营能力</span>
            <span class="fnnl1">研学课本进校园、学校智慧化能力 </span>
            <el-button disabled type="text" class="ljxq2">敬请期待</el-button>
          </div>
          <div class="box5">
            <span class="yyfn2">旅游产品分销平台</span>
            <span class="fnnl2"
              >专业的互联网技术团队打造安全、稳定、高效的旅游分销系统
            </span>
            <el-button
              type="text"
              class="ljxq3"
              @click="jump('/traveldistribution')"
              >了解详情 ></el-button
            >
          </div>
          <div class="box6">
            <span class="yyfn3">电商小程序</span>
            <span class="fnnl3"
              >为景区周边业态（小景区、民宿、特产店）提供独立的微信小程序
            </span>
            <el-button type="text" class="ljxq4" @click="jump('/mini_program')"
              >了解详情 ></el-button
            >
          </div>
          <div class="box7">
            <span class="yyfn4">视觉设计</span>
            <span class="fnnl4"
              >让文创设计和智慧突破空间界限，给用户视觉最美的享受
            </span>
            <el-button
              disabled
              type="text"
              class="ljxq5"
              @click="jump('/The_visual_design')"
              >敬请期待</el-button
            >
          </div>
        </div>
        <div class="bg2">
          <img v-lazy="require('./img/bg2.webp')" alt="" class="bg22" />
          <span class="yxl">营销类</span>
          <span class="czxq"
            >整合资源，营造氛围，精心策划，强势营销，创造需求</span
          >
        </div>
        <div class="xbox1">
          <div class="xbox2">
            <span class="ppyx">品牌营销</span>
            <span class="yxfa"
              >凝练品牌价值，塑造可感知、可体验、可管理的品牌营销方案</span
            >
            <el-button type="text" class="aljxq" @click="jump('/brand_mark')"
              >了解详情 ></el-button
            >
          </div>
          <div class="xbox3">
            <span class="yxsf">营销优化算法</span>
            <span class="zyjz"
              >敏锐响应游客需求，促进游客消费转化，全方位激活目的地资源价值</span
            >
            <el-button
              type="text"
              class="bljxq"
              @click="jump('/optimization_algorithm')"
              >了解详情 ></el-button
            >
          </div>
          <div class="xbox8">
            <span class="yxsf">影谱智慧影像</span>
            <span class="zyjz">用AI助力影像价值挖掘</span>
            <el-button
              type="text"
              class="bljxq"
              @click="jump('/shadow_spectrum')"
              >了解详情 ></el-button
            >
          </div>
          <div class="xbox5">
            <span class="xft">字节跳动</span>
            <span class="kszx">多端数据互通，视频化 </span>
            <el-button type="text" class="cljxq" @click="jump('/ByteDance')"
              >了解详情 ></el-button
            >
          </div>
          <div class="xbox6">
            <span class="zjtd">知识图谱</span>
            <span class="jjxzz"
              >集成图数据库、图计算引擎和图可视化分析的一站式平台+电商实现拓宽新渠道解决新增长
            </span>
            <el-button type="text" class="dljxq" @click="jump('/wisdom')"
              >了解详情 ></el-button
            >
          </div>
        </div>
      </div>
      <Header></Header>
      <Footer></Footer>
    </main>
  </div>
</template>

<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
//引入jquery
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.Banner {
  position: relative;
  left: 0;
  top: 0;
  height: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 600px;
    width: 100%;
  }
  .banner {
    height: 600px;
    width: 100%;
    // position: absolute;
    // left: 0;
    // top: 0;
  }

  .yyfn {
    width: 206px;
    height: 67px;
    font-size: 48px;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 230px;
    left: 361px;
    text-align: left;
  }
  .js {
    width: 600px;
    height: 66px;
    font-size: 24px;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 321px;
    left: 361px;
    text-align: left;
  }
}
.bg1 {
  height: 1300px;
  position: relative;
  .yy {
    width: 301px;
    height: 520px;
    // background-image: url(./img/bg1.png);
    background-size: 100%;
    position: absolute;
    top: 80px;
    left: 359px;
    display: flex;
    align-items: center;
    justify-content: center;
    .a {
      width: 301px;
      height: 520px;
    }
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 520ssspx;
      height: 100%;
    }

    .yyl {
      width: 240px;
      height: 60px;
      font-size: 28px;
      color: #ffffff;
      position: absolute;
      top: 24px;
      left: 24px;
      text-align: left;
      line-height: 15px;
    }
    .yyjs {
      width: 252px;
      height: 25px;
      font-size: 18px;
      color: rgba(255, 255, 255, 0.8);
      position: absolute;
      top: 80px;
      left: 24px;
      text-align: left;
      line-height: 28px;
    }
  }
  .box1 {
    width: 900px;
    height: 520px;
    position: absolute;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    top: 80px;
    left: 660px;
  }
  .box2 {
    width: 300px;
    height: 260px;
    position: absolute;
    top: 0;
    left: 0;
    .ghyy {
      width: 273px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 24px;
      left: 26px;
      text-align: left;
    }
    .cysj {
      width: 252px;
      height: 130px;
      font-size: 16px;
      color: #999999;
      position: absolute;
      top: 66px;
      left: 24px;
      text-align: left;
    }
    .ljxq {
      position: absolute;
      top: 214px;
      left: 24px;
      width: 171px;
      min-height: 22px;
      font-size: 16px;
      color: #007aff;
      text-align: left;
    }
  }
  .box3 {
    width: 300px;
    height: 260px;
    position: absolute;
    top: 0;
    left: 300px;
    .yyfn {
      width: 273px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 24px;
      left: 26px;
      text-align: left;
    }
    .fnnl {
      width: 252px;
      height: 130px;
      font-size: 16px;
      color: #999999;
      position: absolute;
      top: 66px;
      left: 24px;
      text-align: left;
    }
    .ljxq1 {
      position: absolute;
      top: 214px;
      left: 24px;
      width: 171px;
      min-height: 22px;
      font-size: 16px;
      color: #007aff;
      text-align: left;
    }
  }
  .box2,
  .box3,
  .box5,
  .box6 {
    border-right: 1px solid #e8e8e8;
  }
  .box5,
  .box6,
  .box7 {
    border-top: 1px solid #e8e8e8;
  }
  .box4 {
    width: 300px;
    height: 260px;
    position: absolute;
    top: 0;
    left: 600px;
    .yyfn1 {
      width: 273px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 24px;
      left: 26px;
      text-align: left;
    }
    .fnnl1 {
      width: 252px;
      height: 130px;
      font-size: 16px;
      color: #999999;
      position: absolute;
      top: 66px;
      left: 24px;
      text-align: left;
    }
    .ljxq2 {
      position: absolute;
      top: 214px;
      left: 24px;
      width: 171px;
      min-height: 22px;
      font-size: 16px;
      color: rgba(170, 169, 169, 0.3);
      text-align: left;
    }
  }
  .box5 {
    width: 300px;
    height: 260px;
    position: absolute;
    top: 260px;
    left: 0;
    .yyfn2 {
      width: 273px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 24px;
      left: 26px;
      text-align: left;
    }
    .fnnl2 {
      width: 252px;
      height: 130px;
      font-size: 16px;
      color: #999999;
      position: absolute;
      top: 66px;
      left: 24px;
      text-align: left;
    }
    .ljxq3 {
      position: absolute;
      top: 214px;
      left: 24px;
      width: 171px;
      min-height: 22px;
      font-size: 16px;
      color: #007aff;
      text-align: left;
    }
  }
  .box6 {
    width: 300px;
    height: 260px;
    position: absolute;
    top: 260px;
    left: 300px;
    .yyfn3 {
      width: 273px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 24px;
      left: 26px;
      text-align: left;
    }
    .fnnl3 {
      width: 252px;
      height: 130px;
      font-size: 16px;
      color: #999999;
      position: absolute;
      top: 66px;
      left: 24px;
      text-align: left;
    }
    .ljxq4 {
      position: absolute;
      top: 214px;
      left: 24px;
      width: 171px;
      min-height: 22px;
      font-size: 16px;
      color: #007aff;
      text-align: left;
    }
  }
  .box7 {
    width: 300px;
    height: 260px;
    position: absolute;
    top: 260px;
    left: 600px;
    .yyfn4 {
      width: 273px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 24px;
      left: 26px;
      text-align: left;
    }
    .fnnl4 {
      width: 252px;
      height: 130px;
      font-size: 16px;
      color: #999999;
      position: absolute;
      top: 66px;
      left: 24px;
      text-align: left;
    }
    .ljxq5 {
      position: absolute;
      top: 214px;
      left: 24px;
      width: 171px;
      min-height: 22px;
      font-size: 16px;
      color: rgba(170, 169, 169, 0.3);
      text-align: left;
    }
  }
}
.bg2 {
  width: 301px;
  height: 520px;
  // background-image: url(./img/bg2.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 680px;
  left: 359px;
  display: flex;
  align-items: center;
  justify-content: center;
  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 520px;
    width: 100%;
  }
  .bg22 {
    width: 301px;
    height: 520px;
  }
  .yxl {
    width: 240px;
    height: 40px;
    font-size: 28px;
    color: #ffffff;
    position: absolute;
    top: 24px;
    left: 24px;
    text-align: left;
  }
  .czxq {
    width: 252px;
    height: 50px;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 80px;
    left: 24px;
    text-align: left;
    line-height: 28px;
  }
}
.xbox1 {
  width: 900px;
  height: 520px;
  position: absolute;
  border-top: 1px solid #e8e8e8;
  // border-right: 1px solid #e8e8e8;
  // border-bottom: 1px solid #e8e8e8;
  top: 680px;
  left: 660px;
  .xbox2 {
    width: 300px;
    height: 260px;
    position: absolute;
    top: 0;
    left: 0;
    .ppyx {
      min-width: 80px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 24px;
      left: 24px;
      text-align: left;
    }
    .yxfa {
      width: 252px;
      // height: 130px;
      font-size: 16px;
      color: #999999;
      position: absolute;
      top: 66px;
      left: 24px;
      text-align: left;
    }
    .aljxq {
      width: 83px;
      min-height: 22px;
      font-size: 16px;
      color: #007aff;
      position: absolute;
      top: 214px;
      left: 24px;
    }
  }
  .xbox3 {
    width: 300px;
    height: 260px;
    position: absolute;
    top: 0;
    left: 450px;
    .yxsf {
      min-width: 9.167vw;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 24px;
      left: 24px;
      text-align: left;
    }
    .zyjz {
      width: 252px;
      // height: 130px;
      font-size: 16px;
      color: #999999;
      position: absolute;
      top: 66px;
      left: 24px;
      text-align: left;
    }
    .bljxq {
      width: 83px;
      min-height: 22px;
      font-size: 16px;
      color: #007aff;
      position: absolute;
      top: 214px;
      left: 24px;
    }
  }
  .xbox2,
  .xbox3 {
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
  }
  .xbox2,
  .xbox5 {
    border-right: 1px solid #e8e8e8;
  }
  .xbox5,
  .xbox6 {
    border-bottom: 1px solid #e8e8e8;
  }
  .xbox6 {
    border-right: 1px solid #e8e8e8;
  }
  .xbox3 {
    width: 300px;
    height: 260px;
    position: absolute;
    top: 0;
    left: 300px;
    .yxsf {
      min-width: 80px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 24px;
      left: 24px;
      text-align: left;
    }
    .zyjz {
      width: 252px;
      height: 130px;
      font-size: 16px;
      color: #999999;
      position: absolute;
      top: 66px;
      left: 24px;
      text-align: left;
    }
    .bljxq {
      width: 83px;
      min-height: 22px;
      font-size: 16px;
      color: #007aff;
      position: absolute;
      top: 214px;
      left: 24px;
    }
  }
  .xbox5 {
    width: 300px;
    height: 260px;
    position: absolute;
    top: 260px;
    left: 0px;
    .xft {
      min-width: 80px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 24px;
      left: 24px;
      text-align: left;
    }
    .kszx {
      width: 252px;
      // height: 13s0px;
      font-size: 16px;
      color: #999999;
      position: absolute;
      top: 66px;
      left: 24px;
      text-align: left;
    }
    .cljxq {
      width: 83px;
      min-height: 22px;
      font-size: 16px;
      color: #007aff;
      position: absolute;
      top: 214px;
      left: 24px;
    }
  }
  .xbox6 {
    width: 300px;
    height: 260px;
    position: absolute;
    top: 260px;
    left: 300px;
    .zjtd {
      min-width: 80px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 24px;
      left: 24px;
      text-align: left;
    }
    .jjxzz {
      width: 252px;
      // height: 130px;
      font-size: 16px;
      color: #999999;
      position: absolute;
      top: 66px;
      left: 24px;
      text-align: left;
    }
    .dljxq {
      width: 83px;
      min-height: 22px;
      font-size: 16px;
      color: #007aff;
      position: absolute;
      top: 214px;
      left: 24px;
    }
  }
  .xbox8 {
    border-bottom: 1px solid #e8e8e8;
    // border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
  }
  .xbox8 {
    width: 300px;
    height: 260px;
    position: absolute;
    top: 0px;
    left: 600px;
    .yxsf {
      min-width: 60px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 24px;
      left: 24px;
      text-align: left;
    }
    .zyjz {
      width: 252px;
      font-size: 16px;
      color: #999999;
      position: absolute;
      top: 66px;
      left: 24px;
      text-align: left;
    }
    .bljxq {
      width: 83px;
      min-height: 22px;
      font-size: 16px;
      color: #007aff;
      position: absolute;
      top: 214px;
      left: 24px;
    }
  }
  .xbox7 {
    border-left: 1px solid #e8e8e8;
  }
  .xbox7 {
    width: 300px;
    height: 260px;
    position: absolute;
    top: 260px;
    left: 600px;
    .zjtd {
      min-width: 80px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 24px;
      left: 24px;
      text-align: left;
    }
    .jjxzz {
      width: 252px;
      // height: 130px;
      font-size: 16px;
      color: #999999;
      position: absolute;
      top: 66px;
      left: 24px;
      text-align: left;
    }
    .dljxq {
      width: 83px;
      min-height: 22px;
      font-size: 16px;
      color: #007aff;
      position: absolute;
      top: 214px;
      left: 24px;
    }
  }
}
</style>
